export function downloadJson(obj: any, filename: string) {
  const str = JSON.stringify(obj, null, 2);
  const blob = new Blob([str], { type: 'text/json' });

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
}

export function readJson<T = any>() {
  return new Promise<T>((res, rej) => {
    const input = document.createElement('input');
    input.accept = 'application/JSON';
    input.type = 'file';
    input.addEventListener('change', () => {
      const file = input.files && input.files.item(0);
      if (!file) return rej();

      const reader = new FileReader();
      reader.onload = () => res(JSON.parse(reader.result as string));
      reader.readAsText(file);
    });

    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  });
}
