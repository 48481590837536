
































































































































































































































import { Invoice, Research } from '@app/models';
import fb from 'firebase/app';
import _ from 'lodash';
import Papa from 'papaparse';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import ArrowUp from '../../components/6-other/arrow-up.vue';
import { smileys } from '../../data/smileys';
import { ResearchModule } from '../../store/modules/research-module';
import { ExportDataModule } from '../../store/modules/exportdata-module';
import { downloadJson, readJson } from '../../utils/download';
import { formatDate } from '../../utils/simple-functions';

@Component({
  components: { ArrowUp },
})
export default class Home extends Vue {
  public research = getModule(ResearchModule);
  public exportdata = getModule(ExportDataModule);
  public loading: boolean = false;
  public dialogActive: boolean = false;
  public dialogAccepted!: boolean;

  public get fields() {
    const fb = this.$customerNameShort === 'fb';

    return [
      {
        key: 'date',
        sortable: true,
        thClass: 'th-date',
        tdClass: 'td-date',
      },
      {
        key: 'title',
        label: this.$t('research-title'),
        sortable: true,
        thClass: 'th-title',
        tdClass: 'td-title',
      },
      {
        key: 'responses',
        sortable: true,
        thClass: 'th-responses',
        tdClass: 'td-responses',
      },
      fb && {
        key: 'grade',
        sortable: true,
        thClass: 'th-grade',
        tdClass: 'td-grade',
      },
      fb && {
        key: 'continuity',
        sortable: true,
        thClass: 'th-continuity',
        tdClass: 'td-continuity',
      },
      fb && {
        key: 'strategic-values',
        thClass: 'th-strategic-values',
        tdClass: 'td-strategic-values',
      },
      {
        key: 'button',
        label: this.$t('status'),
        thClass: 'th-button',
        tdClass: 'td-button',
      },
      {
        key: 'actions',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public get items(): Item[] {
    return this.research.data
      .filter(research => {
        return (
          (research.company === this.$company.id ||
            research.company === '<demo>') &&
          (!this.$company.permission('research-overview')
            ? research.status === 'active'
            : true)
        );
      })
      .map(research => {
        const start = research.date.start && research.date.start.toDate();
        const end = research.date.end && research.date.end.toDate();

        const date = [];
        if (start) date.push(formatDate(start));
        if (end) date.push(formatDate(end));

        const resp = this.research.respondents.filter(
          r => r.rid === research.id
        );

        const responses =
          research && resp.length
            ? Math.round((research.dashboard!.responses / resp.length) * 100)
            : 0;

        return {
          button: this.getStatusButton(research),
          continuity: research ? research.dashboard!.continuity + '%' : '',
          date,
          demo: research.company === '<demo>',
          grade: research
            ? ((research.dashboard!.grade as any).toFixed(1) as number)
            : 0,
          id: research.id,
          invoice: this.$company.getInvoice(research.id),
          paid: !research.structure,
          responses: `${responses}% - ${(research && research.goal) || 0}%`,
          status: research.status,
          'strategic-values': research
            ? research.dashboard!['strategic-value']
            : [],
          title: research.title,
          _rowVariant: research.status === 'template' ? 'alt' : undefined,
        };
      });
  }

  public get smiley() {
    const grades = this.items.map(i => i.grade).filter(g => g >= 1);
    const average = grades.reduce((a, b) => a + b, 0) / grades.length;
    const index = Math.round(average / 2 - 1);
    return smileys[isNaN(index) ? 2 : index];
  }

  public async created() {
    await this.research.bind();
  }

  public startResearch(item: Item) {
    this.$nextTick(() =>
      this.$firebase.doc(`/research/${item.id}`).update('status', 'active')
    );
  }

  public addResearch() {
    const id = this.$firebase.generateId();
    this.$router.push(`/c/${this.$company.id}/research/${id}`);
  }

  public showRespondents(id: string, title: string) {
    this.$router.push(`/admin/respondent?research=${id}&title=${title}`);
  }

  public async deleteResearch(id: string) {
    const del = await this.$bce.confirm(
      this.$t('action-delete', { item: this.$t('research') }),
      this.$t('action-delete-message', {
        item: this.$t('research').toLowerCase(),
      }),
      { cancel: this.$t('cancel'), ok: this.$t('delete') }
    );

    if (!del) return;

    await this.$firebase.doc(`research/${id}`).delete();
    this.$bce.message(this.$t('deleted-item', { item: this.$t('research') }));
  }

  public async devExportResearch(id: string) {
    const items = new Map<string, any>();

    const addDoc = async (doc: string) => {
      const data = await this.$firebase
        .doc(doc)
        .get()
        .then(snap => this.$firebase.toData<any>(snap));

      items.set(doc, data);
      return data;
    };

    type Collection = fb.firestore.CollectionReference;
    type Query = fb.firestore.Query;
    const addCol = async (col: string, query?: (col: Collection) => Query) => {
      const baseRef = this.$firebase.col(col);
      const ref = query ? query(baseRef) : baseRef;
      const data = await ref
        .get()
        .then(snap => this.$firebase.toData<any>(snap));

      for (const item of data) items.set(`${col}/${item.id}`, item);
      return data;
    };

    await addDoc(`research/${id}`);
    await addCol(`research/${id}/invite`);
    await addCol(`research/${id}/respondent`);
    await addCol('results', col => col.where('rid', '==', id));

    downloadJson((Object as any).fromEntries(items), `research-${id}.json`);
  }

  public async devImportResearch() {
    const data = await readJson();

    const id = this.$firebase.generateId();
    if (!this.$company.id) return;

    let batch = this.$firebase.batch();
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const doc = keys[i];
      const item = data[doc];

      const company =
        'company' in item && item.company === '<demo>'
          ? item.company
          : this.$company.id;

      if (doc.match(/^research\/[^/]*$/)) {
        const research: Research = {
          ...item,
          company,
          date: {
            end: new fb.firestore.Timestamp(item.date.end.seconds, 0),
            start: new fb.firestore.Timestamp(item.date.start.seconds, 0),
          },
          id,
        };

        const path = `research/${id}`;
        batch.set(this.$firebase.doc(path), research);
      }

      if (doc.match(/^research\/[^/]*\/invite\/[^/]*$/)) {
        const code = this.$firebase.generateId();
        const invite = { ...item, code, company, id: code, research: id };

        const path = `research/${id}/invite/${invite.id}`;
        batch.set(this.$firebase.doc(path), invite);
      }

      if (doc.match(/^research\/[^/]*\/respondent\/[^/]*$/)) {
        const respondent = { ...item, company, rid: id };

        const path = `research/${id}/respondent/${respondent.id}`;
        batch.set(this.$firebase.doc(path), respondent);
      }

      if (doc.match(/^results\/[^/]*$/)) {
        const resultId = this.$firebase.generateId();
        const result = { ...item, company, id: resultId, rid: id };

        const path = `results/${result.id}`;
        batch.set(this.$firebase.doc(path), result);
      }

      if (i % 500 === 0) {
        await batch.commit();
        batch = this.$firebase.batch();
      }
    }

    await batch.commit();
    console.log('import complete');
  }

  public getStatusButton(research: Research): Item['button'] {
    switch (research.status) {
      default:
      case 'template':
        return {
          label: 'status-template' /* TO DO: Waarom zelfde label als ready?' */,
          name: 'research-details-a',
          path: `/c/${this.$company.id}/research/${research.id}`,
          icon: 'fas:exclamation-triangle',
          tooltip: 'Het onderzoek mist nog een aantal gegevens.',
        };
      case 'ready':
        return {
          label: 'status-template',
          name: 'research-details-a',
          path: `/c/${this.$company.id}/research/${research.id}`,
          icon: 'fas:edit',
          tooltip:
            'Het onderzoek wordt uitgestuurd op de aangegeven startdatum.',
        };
      case 'active':
        return {
          label: 'status-active',
          name: 'dashboard',
          path: `/dashboard/${research.id}`,
          icon: 'fas:chart-line',
          tooltip: 'Het onderzoek is actief.',
        };
      case 'complete':
        return {
          label: 'status-complete',
          name: 'dashboard',
          path: `/dashboard/${research.id}`,
          icon: 'fas:archive',
          tooltip: 'Het onderzoek is afgerond.',
        };
    }
  }

  public canEdit(item: Item) {
    return item.status === 'template' || item.status === 'ready';
  }

  public canDelete(item: Item) {
    return (
      !item.demo &&
      (this.$user.admin ||
        (this.$company.permission('research-create') &&
          item.status !== 'active'))
    );
  }

  public async createExport(id: string, researchTitle: string, status: string) {
    await this.exportdata.bind(id, status);

    const result = this.exportdata.data;
    const { colRowFormat, headers } = await this.dataToColRowFormat(
      result,
      researchTitle,
      id
    );

    const csvString = await this.createCsvString(colRowFormat, headers);

    return this.download(`${researchTitle}-${id}-.csv`, csvString);
  }

  public async dataToColRowFormat(
    results: any,
    researchTitle: string,
    researchId: string
  ) {
    const colRowFormat = [];
    // TODO: incorrect sorting
    const order = results.sort(
      (resultA: any, resultB: any) =>
        parseFloat(resultA.nr) - parseFloat(resultB.nr)
    );

    const groupedDataUser = _.groupBy(results, 'respondent.user_id');
    const headers: string[] = _.uniqBy(order, 'elementId').map(
      (res: any) => res.question.questionText
    );
    headers.unshift('user_id', 'email', 'research_name', 'target');

    for (let user in groupedDataUser) {
      let hashTable = {} as any;

      groupedDataUser[user].forEach(result => {
        const { answer, question, respondent } = result;
        const { email, target, name, user_id } = respondent;
        const questionText = question.questionText;
        const answerValue = this.getValueByType(answer, question);

        hashTable = {
          ...hashTable,
          [questionText]: answerValue,
          research_name: researchTitle,
          email,
          user_id,
          target,
        };
      });

      const totalTime = await this.respondentTotalTime(researchId, user);
      hashTable['total_time'] = Math.floor(totalTime) + 'min';
      colRowFormat.push(hashTable);
    }
    return { colRowFormat, headers };
  }

  public respondentTotalTime(researchId: string, respondentId: string) {
    return this.$firebase
      .doc(`research/${researchId}/respondent/${respondentId}`)
      .get()
      .then(async doc => {
        if (doc.exists) return doc!.data()!.total_time;
        return 0;
      });
  }

  public getValueByType(answer: any, question: any) {
    switch (answer.type) {
      case 'slider':
      case 'input':
      case 'icon':
        return answer.value;
      case 'radio':
        return answer.value.text;
      case 'checkbox':
        return answer.value.reduce((acc: any, curr: any) => {
          return acc + curr.text + ',';
        }, '');
      case 'matrix':
        return answer.value.reduce((acc: any, curr: any, i: number) => {
          if (curr === null || curr === undefined) return acc;
          return (
            acc +
            question.rows[i].text +
            ' - ' +
            question.columns[curr].text +
            '|'
          );
        }, '');
      default:
        return 'parse ERROR';
    }
  }

  public createCsvString(data: any, columns: string[]) {
    return new Promise((res, rej) => {
      try {
        res(
          Papa.unparse(data, {
            quotes: false,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ';',
            header: true,
            newline: '\n',
            skipEmptyLines: true,
            columns,
          })
        );
      } catch (error) {
        console.log(error);
        this.$bce.alert('Parse CSV', error);
        rej(error);
      }
    });
  }

  public download(filename: string, text: any) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public async downloadInvoice(invoice: Invoice) {
    const path = `/company/${invoice.company}/invoice/${invoice.id}.pdf`;
    const downloadUrl = await this.$firebase.file(path).getDownloadURL();

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.click();
  }

  public async toggleInvoice(invoice: Invoice) {
    const path = `company/${invoice.company}/invoice/${invoice.id}`;
    const ref = this.$firebase.doc(path);

    if (!invoice.paid) await ref.update('paid', fb.firestore.Timestamp.now());
    else await ref.update('paid', fb.firestore.FieldValue.delete())
  }
}

interface Item {
  readonly button: {
    readonly icon: string;
    readonly label: string;
    readonly name: string;
    readonly path: string;
    readonly status?: Research.Status;
    readonly tooltip: string;
  };
  readonly continuity: string;
  readonly date: string[];
  readonly demo: boolean;
  readonly grade: number;
  readonly id: string;
  readonly invoice?: Invoice;
  readonly paid: boolean;
  readonly responses: string;
  readonly status: Research.Status;
  readonly ['strategic-values']: string[];
  readonly title: string;
}
