
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ArrowUp extends Vue {
  @Prop({ type: String, default: 'primary' })
  public color!: string;

  @Prop({ type: String, default: '' })
  public text!: string;
}
