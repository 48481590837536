export const smileys = [
  'fas:sad-tear',
  'fas:frown-open',
  'fas:meh',
  'fas:grin',
  'fas:laugh',
];

export const smileysColor = [
  '#ef8b54',
  '#f3ac4c',
  '#f6d24c',
  '#d7f18e',
  '#aad77e',
];
