import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { store } from '../store';

@Module({ dynamic: true, store, name: 'exportdata', namespaced: true })
export class ExportDataModule extends VuexModule {
  private _data: any = [];

  public get data() {
    return this._data;
  }

  @Action({ rawError: true })
  public async bind(researchId: string, status: string) {
    const collection = status === 'complete' ? 'results-complete' : 'results';
    const ref = firebase.col(collection).where('rid', '==', researchId);

    await firebase.bind(this, '_data', ref);
  }
}
